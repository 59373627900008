// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-buoy-js": () => import("./../../../src/templates/buoy.js" /* webpackChunkName: "component---src-templates-buoy-js" */),
  "component---src-templates-coastguard-content-page-js": () => import("./../../../src/templates/coastguard-content-page.js" /* webpackChunkName: "component---src-templates-coastguard-content-page-js" */),
  "component---src-templates-coastguard-section-landing-js": () => import("./../../../src/templates/coastguard-section-landing.js" /* webpackChunkName: "component---src-templates-coastguard-section-landing-js" */),
  "component---src-templates-coastguard-section-welcome-js": () => import("./../../../src/templates/coastguard-section-welcome.js" /* webpackChunkName: "component---src-templates-coastguard-section-welcome-js" */),
  "component---src-templates-harbours-arrivals-page-js": () => import("./../../../src/templates/harbours-arrivals-page.js" /* webpackChunkName: "component---src-templates-harbours-arrivals-page-js" */),
  "component---src-templates-harbours-content-page-js": () => import("./../../../src/templates/harbours-content-page.js" /* webpackChunkName: "component---src-templates-harbours-content-page-js" */),
  "component---src-templates-harbours-safety-security-js": () => import("./../../../src/templates/harbours-Safety-Security.js" /* webpackChunkName: "component---src-templates-harbours-safety-security-js" */),
  "component---src-templates-harbours-section-landing-js": () => import("./../../../src/templates/harbours-section-landing.js" /* webpackChunkName: "component---src-templates-harbours-section-landing-js" */),
  "component---src-templates-harbours-section-welcome-js": () => import("./../../../src/templates/harbours-section-welcome.js" /* webpackChunkName: "component---src-templates-harbours-section-welcome-js" */),
  "component---src-templates-marinas-content-full-page-js": () => import("./../../../src/templates/marinas-content-full-page.js" /* webpackChunkName: "component---src-templates-marinas-content-full-page-js" */),
  "component---src-templates-marinas-content-page-js": () => import("./../../../src/templates/marinas-content-page.js" /* webpackChunkName: "component---src-templates-marinas-content-page-js" */),
  "component---src-templates-marinas-section-landing-js": () => import("./../../../src/templates/marinas-section-landing.js" /* webpackChunkName: "component---src-templates-marinas-section-landing-js" */),
  "component---src-templates-marinas-section-welcome-js": () => import("./../../../src/templates/marinas-section-welcome.js" /* webpackChunkName: "component---src-templates-marinas-section-welcome-js" */),
  "component---src-templates-news-archive-js": () => import("./../../../src/templates/news-archive.js" /* webpackChunkName: "component---src-templates-news-archive-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-news-landing-js": () => import("./../../../src/templates/news-landing.js" /* webpackChunkName: "component---src-templates-news-landing-js" */),
  "component---src-templates-opening-times-js": () => import("./../../../src/templates/opening-times.js" /* webpackChunkName: "component---src-templates-opening-times-js" */),
  "component---src-templates-pdf-js": () => import("./../../../src/templates/pdf.js" /* webpackChunkName: "component---src-templates-pdf-js" */),
  "component---src-templates-ports-content-full-page-js": () => import("./../../../src/templates/ports-content-full-page.js" /* webpackChunkName: "component---src-templates-ports-content-full-page-js" */),
  "component---src-templates-ports-content-page-js": () => import("./../../../src/templates/ports-content-page.js" /* webpackChunkName: "component---src-templates-ports-content-page-js" */),
  "component---src-templates-ports-section-landing-js": () => import("./../../../src/templates/ports-section-landing.js" /* webpackChunkName: "component---src-templates-ports-section-landing-js" */),
  "component---src-templates-ports-section-welcome-js": () => import("./../../../src/templates/ports-section-welcome.js" /* webpackChunkName: "component---src-templates-ports-section-welcome-js" */),
  "component---src-templates-redirect-template-cruise-js": () => import("./../../../src/templates/redirect-templateCruise.js" /* webpackChunkName: "component---src-templates-redirect-template-cruise-js" */),
  "component---src-templates-redirect-template-fines-js": () => import("./../../../src/templates/redirect-templateFines.js" /* webpackChunkName: "component---src-templates-redirect-template-fines-js" */),
  "component---src-templates-redirect-template-jobs-js": () => import("./../../../src/templates/redirect-templateJobs.js" /* webpackChunkName: "component---src-templates-redirect-template-jobs-js" */),
  "component---src-templates-redirect-template-js": () => import("./../../../src/templates/redirect-template.js" /* webpackChunkName: "component---src-templates-redirect-template-js" */),
  "component---src-templates-redirect-template-parking-js": () => import("./../../../src/templates/redirect-templateParking.js" /* webpackChunkName: "component---src-templates-redirect-template-parking-js" */),
  "component---src-templates-redirect-template-shells-js": () => import("./../../../src/templates/redirect-templateShells.js" /* webpackChunkName: "component---src-templates-redirect-template-shells-js" */),
  "component---src-templates-redirect-template-trace-js": () => import("./../../../src/templates/redirect-templateTrace.js" /* webpackChunkName: "component---src-templates-redirect-template-trace-js" */),
  "component---src-templates-search-page-js": () => import("./../../../src/templates/search-page.js" /* webpackChunkName: "component---src-templates-search-page-js" */),
  "component---src-templates-tidal-window-js": () => import("./../../../src/templates/tidal-window.js" /* webpackChunkName: "component---src-templates-tidal-window-js" */),
  "component---src-templates-tide-page-js": () => import("./../../../src/templates/tide-page.js" /* webpackChunkName: "component---src-templates-tide-page-js" */),
  "component---src-templates-weather-page-js": () => import("./../../../src/templates/weather-page.js" /* webpackChunkName: "component---src-templates-weather-page-js" */)
}

